<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ auction.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_AUCTIONS)"
            @click="deleteAuction"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_AUCTIONS)"
            @click="editAuction"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("AUCTIONS.NAME") }}</dt>
          <dd>
            {{ auction.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("AUCTIONS.AUCTION_TYPE") }}</dt>
          <dd>
            <span>
              {{ $t(`AUCTIONS.AUCTION_TYPE_${auction.auction_type}`) }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("AUCTIONS.DELAY_BEFORE_END_TO_BET_AGAIN") }}
          </dt>
          <dd>
            <span>
              {{
                auction.delay_before_end_to_bet_again
                  ? `${auction.delay_before_end_to_bet_again} s`
                  : "-"
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("AUCTIONS.EXTENSION_TIME") }}
          </dt>
          <dd>
            <span>
              {{ auction.extension_time ? `${auction.extension_time} s` : "-" }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("AUCTIONS.EXPRESS_AUCTION_DURATION") }}
          </dt>
          <dd>
            <span>
              {{
                auction.express_auction_duration
                  ? `${auction.express_auction_duration} s`
                  : "-"
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("AUCTIONS.SALE_CONFIRMATION_DEADLINE") }}
          </dt>
          <dd>
            <span>
              {{
                auction.sale_confirmation_deadline
                  ? `${auction.sale_confirmation_deadline} jour(s)`
                  : "-"
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("AUCTIONS.AUTHORIZED_PAYMENTS") }}</dt>
          <dd>
            <span v-for="(item, key) in auction.authorized_payments" :key="key">
              {{ $t(`AUCTIONS.PAYMENT_SOURCE_${item}`) }}
              {{ auction.authorized_payments.length - 1 > key ? "," : " " }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $formatDate(auction.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $formatDate(auction.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "@/components/Users.vue";
import Contacts from "@/components/Contacts.vue";

export default {
  name: "auction-view-global",

  props: ["auction"],

  components: {
    Users,
    Contacts,
  },

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    auctionUpdated() {
      this.$emit("auctionUpdated", true);
    },
    editAuction() {
      this.$emit("onEditAuction", this.auction);
    },

    deleteAuction() {
      this.$emit("onDeleteAuction", this.auction);
    },
  },

  mounted() {},

  watch: {
    auction(auction) {},
  },
};
</script>
